import React from 'react';
// eslint-disable-next-line global-require
const utils = typeof window !== 'undefined' ? require('@uvocore-front/utils') : null;

class CookiePolicyPopUp extends React.Component {
  componentDidMount() {
    utils.loadScripts(
      '/assets/cookie-policy.js',
      this.instance,
    );
  }

  render() {
    return (
      <div
        ref={(el) => {
          this.instance = el;
        }}
      />
    );
  }
}

export default CookiePolicyPopUp;
