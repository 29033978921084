import React, { Component } from 'react';
import { globalHistory } from '@reach/router';
import './NotifyWidget.scss';

// eslint-disable-next-line global-require
const utils = typeof window !== 'undefined' ? require('@uvocore-front/utils') : null;

class NotifyWidget extends Component {
  componentDidMount() {
    const { pathname } = globalHistory.location;
    if (
      this.widget
      && pathname !== '/dashboard/orders/create/'
    ) {
      utils.getWidget({
        widgetLink: '/assets/js/notifyWidget.min.js',
        fn: () => {
          window.NotifyWidget({
            siteId: '169',
            container: '.NotifyWidget-banner',
          });
        },
      });
    }
  }

  render() {
    return <div ref={(ref) => { this.widget = ref; }} data-notify-widget="" className="NotifyWidget-banner" />;
  }
}

export default NotifyWidget;
