import React from 'react';
import { Link } from 'gatsby';

import './index.scss';


const Footer = () => (
  <footer className="footer">
    <div className="footer__container container">

      <div className="footer__menu">
        <div className="footer__menu__item footer__logo">
          <Link to="/">
            <img
              data-observed-image
              data-src="/img/logo.svg"
              alt="Takeaway Essay"
              width={170}
              height={46}
            />
          </Link>
        </div>
        <div className="footer__menu__item footer__company">
          <h4>
            Company
          </h4>
          <ul>
            <li>
              <Link
                to="/pricing.html"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/our-samples.html"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Samples
              </Link>
            </li>
            <li>
              <Link
                to="/guarantees.html"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Guarantees
              </Link>
            </li>
            <li>
              <Link
                to="/about-us.html"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                to="/frequently-asked-questions.html"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__menu__item footer__terms">
          <h4>
            Terms
          </h4>
          <ul>
            <li>
              <Link
                to="/terms-conditions.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Terms and conditions
              </Link>
            </li>
            <li>
              <Link
                to="/cookie-policy.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Cookie policy
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Privacy policy
              </Link>
            </li>
            <li>
              <Link
                to="/social-responsibility-notice.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Director’s notice on
                <br />
                {' '}
                social responsibility
              </Link>
            </li>
            <li>
              <Link
                to="/money-back-guarantee.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Money back guarantee
              </Link>
            </li>
            <li>
              <Link
                to="/plagiarism-free-guarantee.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Plagiarism-Free guarantee
              </Link>
            </li>
            <li>
              <Link
                to="/revisions-policy.html"
                rel="nofollow"
                className="footer__menu__link"
                activeClassName="footer__menu__link_active"
              >
                Revision policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__menu__item footer__we-accept">
          <h4>
            We accept
          </h4>
          <ul className="footer__we-accept__list">
            <li className="footer__we-accept__item visa">
              <img
                data-observed-image
                data-src="/img/icons/visa.svg"
                alt="Visa"
                width="40"
                height="12.92"
              />
            </li>
            <li className="footer__we-accept__item mastercard">
              <img
                data-observed-image
                data-src="/img/icons/mastercard.svg"
                alt="MasterCard"
                width="28"
                height="17.28"
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="footer__copyright">
        <div className="footer__copyright__item">
          <p>
            &copy; 2018&mdash;
            {new Date().getFullYear()}
            {' '}
            All Rights Reserved.
          </p>
        </div>
      </div>

    </div>
  </footer>
);

export default Footer;
