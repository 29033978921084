/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { detect } from 'detect-browser';
import isBrowser from '../../utils/helpers/isBrowser';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CookiePolicyPopUp from '../../components/CookiePolicyPopUp';
import CommunicationPanel from '../../components/CommunicationPanel';
import NotifyWidget from '../../components/NotifyWidget';

import './index.scss';

// eslint-disable-next-line global-require
const utils = typeof window !== 'undefined' ? require('@uvocore-front/utils') : null;

class LayoutForSimplePage extends React.Component {
  state = {
    browserName: '',
    browserOS: '',
  };

  componentDidMount() {
    const browser = detect();

    if (browser) {
      this.setState({
        browserName: `browser-${browser.name.toLowerCase()}`,
        browserOS: `os-${browser.os.toLowerCase()}`,
      });
    }

    if (isBrowser && document.querySelector('[data-observed-image]')) {
      utils.observeElement({
        element: '[data-observed-image]',
        marginValue: 200,
        callback: (img) => {
          const dataSrc = img.getAttribute('data-src');
          const dataSrcset = img.getAttribute('data-srcset');

          if (dataSrc) img.src = dataSrc;
          if (dataSrcset) img.srcset = dataSrcset;
        },
      });
    }

    if (isBrowser && document.querySelector('[data-observed-image]')) {
      utils.observeElement({
        element: '[data-observed-bg]',
        marginValue: 250,
        callback: (bgImg) => {
          bgImg.classList.add('load-bg');
        },
      });
    }
  }


  render() {
    const {
      browserName,
      browserOS,
    } = this.state;
    const {
      children,
      pageWrapperClassName,
      headerClassName,
      hideFooter,
    } = this.props;
    const paddingTop = headerClassName === 'header_white-bg';

    return (
      <div
        className={classNames(
          'page-wrapper',
          pageWrapperClassName,
          browserName,
          browserOS,
        )}
      >
        <NotifyWidget />
        <Header className={headerClassName} />
        <main
          className={classNames(
            'main',
            paddingTop && 'main_padding-top',
          )}
          role="main"
        >
          {children}
        </main>
        {hideFooter || <Footer />}
        <CookiePolicyPopUp />
        <CommunicationPanel />
      </div>
    );
  }
}

LayoutForSimplePage.propTypes = {
  children: PropTypes.node,
  pageWrapperClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  hideFooter: PropTypes.bool,
};

LayoutForSimplePage.defaultProps = {
  children: undefined,
  pageWrapperClassName: undefined,
  headerClassName: 'header_white-bg',
  hideFooter: false,
};

export default LayoutForSimplePage;
