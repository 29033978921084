export default (url, options = {}) => {
  const {
    method = 'GET',
    headers,
    body = null,
    credentials = 'omit',
  } = options;

  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.open(method, url, true);

    request.onreadystatechange = () => {
      if (request.readyState !== 4) return;

      if (request.status >= 400) {
        reject(new Error('Bad request'));

        return;
      }

      resolve({
        json: () => JSON.parse(request.responseText),
        text: () => request.responseText,
      });
    };

    request.onerror = () => {
      reject(new Error('Request failed'));
    };

    request.ontimeout = () => {
      reject(new Error('Request timeout'));
    };

    if (headers) {
      Object.keys(headers).forEach((headerName) => {
        request.setRequestHeader(headerName, headers[headerName]);
      });
    }

    if (credentials !== 'omit') {
      request.withCredentials = true;
    }

    request.send(body);
  });
};
