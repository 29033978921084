import fetch from './fetch';


const STORAGE = 'sessionStorage';
const SCOPE_KEY = 'cwt';
const AUTH_KEY = 'auth';
const TIMESTAMP_KEY = 'at';
const LIFETIME = 5 * 60 * 1000;

const isBrowser = typeof window !== 'undefined';
const authKey = [SCOPE_KEY, AUTH_KEY].join('_');
const authTimestampKey = [SCOPE_KEY, AUTH_KEY, TIMESTAMP_KEY].join('_');
const noop = () => {};

let storage = {
  setItem: noop,
  getItem: noop,
  removeItem: noop,
  clear: noop,
};

if (isBrowser) {
  try {
    window[STORAGE].getItem(authKey);

    storage = window[STORAGE];
  } catch (error) {
    //
  }
}

export const getAuth = () => {
  const auth = parseInt(storage.getItem(authKey), 10);

  if (Number.isNaN(auth)) return undefined;

  const timestamp = parseInt(storage.getItem(authTimestampKey), 10);

  if ((Date.now() - timestamp) > LIFETIME) return undefined;

  return Boolean(auth);
};

const setAuth = (auth) => {
  storage.setItem(authKey, (auth ? 1 : 0));

  storage.setItem(authTimestampKey, Date.now());
};

const requestAuth = () => fetch('/api/isLogged.json?api_version=v002', {
  headers: {
    Accept: 'application/json',
  },
  credential: 'same-origin',
})
  .then(response => response.json())
  .then(({ success, errors, isLogged }) => {
    if (!success) {
      throw new Error(errors[0]);
    }

    setAuth(isLogged);

    return isLogged;
  });

export const checkLogged = () => new Promise((resolve) => {
  if (!isBrowser) {
    resolve(false);

    return;
  }

  // const auth = getAuth();

  // if (typeof auth !== 'undefined') {
  //   resolve(auth);
  //
  //   return;
  // }

  resolve(requestAuth());
});
