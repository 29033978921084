// @todo enable next eslint rules
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { throttle } from 'throttle-debounce';
import { checkLogged } from '../../utils/auth';
import BlackLogo from './BlackLogo';
import UserBlock from '../UserBlock';

import './index.scss';


class Header extends Component {
  handleScroll = throttle(100, () => {
    if (window.scrollY > 150) {
      this.setState({ isScroll: true });
    } else {
      this.setState({ isScroll: false });
    }
  });

  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      isScroll: false,
      isAuthorized: false,
    };
    this.onScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document.getElementsByTagName('html')[0].classList.remove('overflow');
    document.getElementsByTagName('body')[0].classList.remove('overflow');

    // check authorization
    checkLogged()
      .then((isAuthorized) => {
        this.setState({ isAuthorized });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        if (__DEVELOPMENT__) console.error(error);
      });

    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  /**
   * toggle menu function
   *
   * @todo remove body classes change
   * Component should not change html directly. This may cause unpredictable state.
   * You should create `Overflow` component at root, witch pass its context to children.
   */
  toggleMenu = () => {
    this.setState(
      ({ isOpenMenu }) => ({ isOpenMenu: !isOpenMenu }),
      () => {
        const { isOpenMenu } = this.state;
        if (isOpenMenu) {
          document.getElementsByTagName('html')[0].classList.add('overflow');
          document.getElementsByTagName('body')[0].classList.add('overflow');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('overflow');
          document.getElementsByTagName('body')[0].classList.remove('overflow');
        }
      },
    );
  };


  render() {
    const {
      isOpenMenu,
      isScroll,
      isAuthorized,
    } = this.state;
    const {
      className,
    } = this.props;

    return (
      <header
        className={classNames(
          'header',
          className,
          isOpenMenu && 'header_open-menu',
          isScroll && 'header_show',
          isAuthorized && 'is-authorized',
        )}
      >

        <div
          className={classNames(
            'header__wrap',
            isScroll && 'header__wrap_fixed',
          )}
        >
          <div className="header__container container">
            <div
              onClick={this.toggleMenu}
              className={classNames(
                'overlay',
                isOpenMenu && 'overlay_show',
              )}
            />

            <Link to="/" className="header__logo-mini">
              <BlackLogo width="131" height="36" />
            </Link>
            <Link to="/" className="header__logo">
              <img
                src="img/logo.svg"
                alt="Takeaway Essay"
                width="131"
                height="36"
              />
            </Link>

            <div
              className={classNames(
                'header__navigation-wrap',
                isOpenMenu && 'header__navigation-wrap_open-menu',
              )}
              data-dest="toggle-menu"
            >
              <nav className="header__navigation" aria-label="Main menu">
                <div className="header__order-now header__order-now_in-burger">
                  <Link
                    to="/dashboard/orders/create/"
                    rel="nofollow"
                    className="header__order-now__btn btn btn_without-bg btn_blue btn_small btn_blue-color"
                    activeClassName="header__order-now__btn_active"
                  >
                    Order now
                  </Link>
                </div>

                <ul className="header__navigation__menu">
                  <li className="header__navigation__menu__item">
                    <Link
                      to="/help-services.html"
                      className="header__navigation__menu__link"
                      activeClassName="header__navigation__menu__link_active"
                    >
                      Essay services
                    </Link>
                  </li>
                  <li className="header__navigation__menu__item">
                    <Link
                      to="/pricing.html"
                      className="header__navigation__menu__link"
                      activeClassName="header__navigation__menu__link_active"
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="header__navigation__menu__item">
                    <Link
                      to="/guarantees.html"
                      className="header__navigation__menu__link"
                      activeClassName="header__navigation__menu__link_active"
                    >
                      Guarantees
                    </Link>
                  </li>
                  <li className="header__navigation__menu__item">
                    <Link
                      to="/our-samples.html"
                      className="header__navigation__menu__link"
                      activeClassName="header__navigation__menu__link_active"
                    >
                      Samples
                    </Link>
                  </li>
                  <li className="header__navigation__menu__item">
                    <Link
                      to="/about-us.html"
                      className="header__navigation__menu__link"
                      activeClassName="header__navigation__menu__link_active"
                    >
                      About us
                    </Link>
                  </li>
                  <li className="header__navigation__menu__item">
                    <Link
                      to="/testimonials.html"
                      className="header__navigation__menu__link"
                      activeClassName="header__navigation__menu__link_active"
                    >
                      Testimonials
                    </Link>
                  </li>
                </ul>

                <div className="header__phone-number header__phone-number_in-burger">
                  Call free:
                  {' '}
                  <a href="tel:+18552605455">
                    1-855-260-5455
                  </a>
                </div>
              </nav>
            </div>

            <div className="header__additional-nav">
              <div className="header__phone-number">
                Call free:
                {' '}
                <a href="tel:+18552605455">
                  1-855-260-5455
                </a>
              </div>

              <div className="header__sign-in">
                {isAuthorized ? (
                  <UserBlock />
                ) : (
                  <Link
                    to="/dashboard"
                    rel="nofollow"
                    className="header__sign-in__btn btn btn_without-bg btn_white btn_small"
                    title="My orders"
                  >
                    Sign in
                  </Link>
                )}
              </div>

              <div className="header__order-now">
                <Link
                  to="/dashboard/orders/create/"
                  rel="nofollow"
                  className="header__order-now__btn btn btn_without-bg btn_blue btn_small btn_blue-color"
                  activeClassName="header__order-now__btn_active"
                >
                  Order now
                </Link>
              </div>

            </div>

            <button
              type="button"
              onClick={this.toggleMenu}
              className={classNames(
                'header__burger',
                isOpenMenu && 'header__burger_active',
                isScroll && 'header__burger_black',
              )}
              data-action="toggle-menu"
              aria-label="toggle menu"
            >
              <span />
            </button>

          </div>
        </div>

      </header>
    );
  }
}

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: 'header_white-bg',
};

export default Header;
